import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="documentacion">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              ¿Qué documentación necesito para circular?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>La documentación querida para circular es:</p>
            <ul className="mb-2">
              <li>Licencia de conducir correspondiente al tipo de vehículo.</li>
              <li>DNI o cédula de identidad.</li>
              <li>Cédula verde.</li>
              <li>
                Cédula azul, en caso de circular en un vehículo que no sea de su propiedad y que la
                cédula verde se encuentre vencida.
              </li>
              <li>
                Oblea de RTO (Revisión Técnica Obligatoria) o VTV (Verificación Técnica Vehicular).
              </li>
              <li>
                Comprobante de póliza de seguro obligatorio vigente: la tarjeta provista por la
                aseguradora constituye prueba suficiente de la vigencia del seguro obligatorio y por
                ello no es necesario presentar el recibo de pago del premio. Así lo establece la
                normativa vigente, a la que se hace referencia en el texto impreso en dicha tarjeta.
                En caso de optar por circular con la póliza de seguro, entonces sí deberá exhibirse
                el comprobante de pago del premio, que puede ser el resumen de tarjeta de crédito o
                resumen de su cuenta bancaria.
              </li>
            </ul>
            <p>
              La posesión de este comprobante obligatorio será prueba suficiente de la vigencia del
              seguro obligatorio de automotores exigido por el art. 68 de la ley N° 24.449. Conforme
              el Art. 2° de la Disposición N° 70/2009 de la Agencia Nacional de Seguridad Vial, la
              falta de portación del recibo de pago de la prima del seguro obligatorio por parte del
              conductor del vehículo, no podrá ser aducida por la Autoridad de Constatación para
              determinar el incumplimiento de los requisitos para la circulación.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Cómo y cuándo recibo la póliza?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              Al completar el formulario de alta de seguro, a la brevedad te enviaremos por correo
              electrónico la póliza.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Se realiza verificación del auto? ¿Tengo que retirar la póliza en alguna sucursal?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              El gran grueso de las coberturas permite verificación de la unidad mediante fotos
              actuales y legibles tomadas desde un celular.
            </p>
            <p>
              Las coberturas de mayor alcance, como las Todo Riesgo con Franquicia, requeriran
              también de una inspección en centros especializados o a domicilio con perito
              verificador.
            </p>
            <p>
              La póliza te será enviada en forma digital a tu correo electrónico, dicho documento es
              suficiente para circular.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Qué es el Certificado de Cobertura?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              El Certificado de Cobertura es un documento temporal que emite la aseguradora para que
              un auto pueda circular mientras dura el proceso de emisión de la póliza final.
            </p>
            <p>
              El Certificado te cubre solamente responsabilidad civil, y se emite al momento de
              contratar tu seguro con una validez de 15 días corridos.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
